<template>
  <div>
    <v-row>
      <v-col cols="12" class="d-flex align-center" style="gap: 5px">
        <v-icon @click="handleBackClick" color="primary" style="cursor: pointer">mdi-arrow-left</v-icon>
        <h2>New Appeal</h2>
        <v-spacer/>
        <h3>Client id: {{ this.clientId }}</h3>
      </v-col>
      <v-col cols="12">
        <v-divider class="mb-6" />
      </v-col>
    </v-row>
    <v-row>
      <v-dialog v-model="errorMessage" width="500">
        <v-card class="pa-16" style="background-color: #263b4f">
          <h2 style="color: #ffffff" class="text-center mb-7">
            {{ errorText }}
          </h2>
          <v-progress-linear
            absolute
            color="red"
            :v-model="errProgressBarValue"
            :buffer-value="bufferValue"
          >
          </v-progress-linear>
        </v-card>
      </v-dialog>
      <v-col cols="12" md="6">
        <v-card class="pa-7">
          <h2 class="ml-1">Denial Letter</h2>
          <br>
          <v-card
            @drop.prevent="drop($event)"
            v-if="!file"
            @dragover.prevent="dragoverData = true"
            @dragenter.prevent="dragoverData = true"
            @dragleave.prevent="dragoverData = false"
            class="d-flex align-center justify-center"
            style="border: 1px dashed #384EB7; background-color: #F8F8FF"
            elevation="0"
          >
            <v-row class="d-flex justify-center align-center">
              <v-col cols="12">
                <div class="p-12">
                  <input
                    type="file"
                    name="fields[assetsFieldHandleUploads][]"
                    id="assetsFieldHandleUploads"
                    class="d-none absolute"
                    @input="onChange"
                    ref="file"
                    accept="application/pdf"
                  />

                  <label for="assetsFieldHandleUploads" class="block cursor-pointer">
                    <span style="cursor: pointer" class="d-flex flex-column align-center mt-8">
                      <!-- <h1>Upload a mandate</h1> -->
                      <v-icon style="font-size: 5rem">mdi-upload</v-icon>
                      <p>Drag & drop PDF or Browse</p>
                    </span>
                  </label>
                </div>
              </v-col>
            </v-row>
          </v-card>
          <v-sheet v-else>
            <div class="pa-6" style="border: 1px solid #E5E7EB; border-radius: 4px;">
              {{ file.name }} - {{ file.size / 1000000 }} MB
            </div>
            <br>
            <div class="d-flex justify-space-between">
              <v-btn @click="handleCancelClick" large color="error">Cancel</v-btn>
              <v-btn :loading="loading" @click="uploadFile" large color="primary">Submit</v-btn>
            </div>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import AuthService from "@/services/auth.service";
  import ClientsService from "@/services/clients.service";

  export default {
    name: "NewAppeal",
    data() {
      return {
        panel: [0],
        dragoverData: false,
        loading: false,
        file: undefined,
        errorMessage: null,
        errorText: null,
        interval: null,
        bufferValue: null,
        errProgressBarValue: null,
        clientId: undefined,
        currentUser: undefined
      };
    },
    methods: {
      handleBackClick() {
        this.$router.push(`/clients/${ this.clientId }/appeals`);
      },
      drop(event) {
        event.preventDefault();
        this.$refs.file.files = event.dataTransfer.files;
        this.onChange(); // Trigger the onChange event manually
        // Clean up
      },
      startBuffer() {
        clearInterval(this.interval);

        this.interval = setInterval(() => {
          this.errProgressBarValue += 2;
          this.bufferValue += 2;
        }, 50);
      },
      async uploadFile() {
        this.loading = true;
        const formData = new FormData();
        formData.append("uploaded_file", this.file);
        formData.append('upload_type', 'denial-letter')

        try {
          const res = await ClientsService.uploadFileToClient(this.clientId, formData)

          this.$store.dispatch('snackbar/showSnackbarMessage', {
            message: 'Upload is submitted',
            duration: 3000,
          });

          this.handleBackClick()
        } finally {
          this.loading = false
        }

      },
      onChange() {
        if (this.$refs.file.files.length === 0) return;
        this.file = this.$refs.file.files[0]

        if (this.file.type !== "application/pdf") {
          this.errorText = "The uploaded file is not a PDF. Please upload a PDF file.";
        } else if (this.file.size > 25000000) {
          this.errorText =
            "The uploaded file is over the File Size limit of 25Mb. Please use a PDF optimizer to make the file smaller and try again.";
        } else {
          this.errorText = null;
        }

        if (this.errorText) {
          this.file = undefined
          const removeMessageAfter = 3500;
          this.errorMessage = true;
          this.startBuffer();
          this.loading = false;
          setTimeout(() => {
            this.errorMessage = false;
            clearInterval(this.interval);
            this.errProgressBarValue = 0;
            this.bufferValue = 0;
          }, removeMessageAfter);
        }

      },
      handleCancelClick() {
        this.file = undefined
      }
    },
    async created() {
      this.clientId = this.$route.params.clientId;
      const data = await AuthService.getCurrentUserFromCookie();
      this.currentUser = data.data;
    },
  };
</script>